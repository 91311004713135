<template>
  <div>
    <iframe :src="frame" width="100%" height="1200px" frameborder="0"></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import { formatPrice, sumdate } from '@/plugins/filters'
import vatDataPrint from '@/@fake-db/data/vatDataPrint.json'
import 'jspdf-autotable'
import shop from '@/api/systemSetting/shop'

export default {
  setup() {
    const { route } = useRouter()
    const vatList = ref(vatDataPrint.data)
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(9.5)
    const lastTable = ref(0)
    const shop_print_type = ref(0)
    const shopData = ref({})
    const receiptData = ref({})
    const frame = ref('')

    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    const printsOrder = () => {
      prints
        .printsOrder({
          order_id_pri: route.value.query.id,
          lang: i18n.locale,
        })
        .then(res => {
          dataTableList.value = res.orderdetail
          receiptData.value = res
          savePDF(res)
        })
    }
    shop.shopGet().then(res => {
      shop_print_type.value = route.value.query.shop_print_type
      shopData.value = res
      printsOrder()
    })
    const generateData = () => {
      const result = []
      let drugTotal = 0
      let drugDiscount = 0
      let priceUnit = 0
      let name = ''
      let count = 0

      dataTableList.value.forEach((item, index) => {
        // drug
        if (route.value.query.showDrugStatus == 'true' && route.value.query.showCourseStatus == 'false') {
          if (item.orderdetail_type_id == '2') {
            drugTotal += +item.orderdetail_total.replaceAll(',', '')
            drugDiscount += +item.orderdetail_discount.replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '')
            name = i18n.t('drug_and_service')
            count += 1
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              formatPrice(priceUnit),
              formatPrice(drugDiscount),
              formatPrice(drugTotal),
            ])
          }
        }

        // check and course
        if (route.value.query.showCourseStatus == 'true' && route.value.query.showDrugStatus == 'false') {
          if (item.orderdetail_type_id == '1' || item.orderdetail_type_id == '3') {
            count += 1
            drugTotal += +item.orderdetail_total.replaceAll(',', '')
            drugDiscount += +item.orderdetail_discount.replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '')
            name = i18n.t('service_and_treatment_fees')
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              formatPrice(priceUnit),
              formatPrice(drugDiscount),
              formatPrice(drugTotal),
            ])
          }
        }

        // all check
        if (route.value.query.showCourseStatus == 'true' && route.value.query.showDrugStatus == 'true') {
          if (item.orderdetail_type_id == '1' || item.orderdetail_type_id == '2' || item.orderdetail_type_id == '3') {
            drugTotal += +item.orderdetail_total.replaceAll(',', '')
            drugDiscount += +item.orderdetail_discount.replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '')
            name = i18n.t('drug_and_treatment_fees')
            count += 1
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              receiptData.value.order_price,
              formatPrice(drugDiscount),
              receiptData.value.order_price,
            ])
          }
        }

        // bold
        if (route.value.query.showDrugStatus == 'false' && route.value.query.showCourseStatus == 'false') {
          result.push([
            `${item.orderdetail_name} (${item.orderdetail_id})`,
            `${item.orderdetail_amount}`,
            item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
            item.orderdetail_price,
            item.orderdetail_discount,
            item.orderdetail_total,
          ])
        }
      })
      result.map((item, index) => (item[0] = `${index + 1}) ${item[0]}`))

      return result
    }

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        format: [80, 210],
        unit: 'mm',
      })
      doc.setProperties({
        title: `INVOICE ${data.order_id} - ${data.order_create}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-bold.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-bold.ttf', 'TH-Niramit', 'bold')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')

      const spacer = 4
      let total = 35
      const ImageToLoad = new Image()
      ImageToLoad.src = shopData.value.shop_image
      doc.addImage(JSON.parse(localStorage.getItem('shopImage')), 'JPEG', 4, 3, 28, 26)
      if (data.order_status_id == '2') {
        doc.setFontSize(20)
        doc.setTextColor('#FF1744')
        doc.text(`(${i18n.t('cancel')})`, 76, 36, null, null, 'right')
      }
      doc.setTextColor('#000')
      doc.setFontSize(12)
      doc.text(`${i18n.t('invoice')}`, 76, 7, null, null, 'right')
      doc.text('[INVOICE]', 76, 10, null, null, 'right')
      doc.text(`${i18n.t('a_number')} ${data.order_id}`, 76, 20, null, null, 'right')
      doc.text(`${i18n.t('date')} ${sumdate(data.order_create)}`, 76, 24, null, null, 'right')
      doc.setLineWidth(0.3)
      doc.setDrawColor('#37474F')
      doc.line(40, 30, 76, 30)

      // Start Address Shop block
      doc.setFontSize(fontTitle.value)
      doc.setFillColor('#000')
      if (shop_print_type.value == '1') {
        total += spacer
        doc.text(`${data.shop_name} ${data.shop_nature}`, 4, total, null, null, 'left')
        total += spacer
        doc.setFontSize(fontTitle.value)
        doc.text(`${i18n.t('numberLicenses')} ${data.shop_license}`, 4, total, null, null, 'left')
        total += spacer
        doc.text(`${i18n.t('tax_id')} ${data.shop_tax}`, 4, total, null, null, 'left')
        total += spacer
        doc.text(`${data.shop_address} ${data.shop_district}`, 4, total, null, null, 'left')
        total += spacer
        doc.text(`${data.shop_amphoe} ${data.shop_province} ${data.shop_zipcode}`, 4, total, null, null, 'left')
        total += spacer
        doc.text(`${i18n.t('tel')} ${data.shop_tel} ${i18n.t('email')} ${data.shop_email}`, 4, total)
        total += spacer
      } else {
        doc.text(`${shopData.value.shop_company_name}`, 4, total, null, null, 'left')
        total += spacer
        doc.setFontSize(fontTitle.value)
        doc.text(`${i18n.t('numberLicenses')} ${data.shop_license}`, 4, total, null, null, 'left')
        total += spacer
        doc.text(`${i18n.t('tax_id')} ${data.shop_tax}`, 4, total, null, null, 'left')
        total += spacer
        doc.text(
          `${shopData.value.shop_company_address} ${shopData.value.shop_company_district}`,
          4,
          total,
          null,
          null,
          'left',
        )
        total += spacer
        doc.text(
          `${shopData.value.shop_company_amphoe} ${shopData.value.shop_company_province} ${shopData.value.shop_company_zipcode}`,
          4,
          total,
          null,
          null,
          'left',
        )
        total += spacer
        doc.text(
          `${i18n.t('tel')} ${shopData.value.shop_company_tel} ${i18n.t('email')} ${shopData.value.shop_company_email}`,
          4,
          total,
        )
        total += spacer - 2
      }
      doc.setLineWidth(0.2)
      doc.setDrawColor('#37474F')
      doc.line(4, total, 76, total)
      total += spacer

      // End Address Shop block

      // start Address customer

      if (route.value.query.showCompany == 'false') {
        doc.text(
          `${i18n.t('contacts')} ${data.customer_name} ${i18n.t('customer_code')} ${data.customer_id}`,
          4,
          total,
          null,
          null,
          'left',
        )
        total += spacer
        doc.text(`${i18n.t('tax_id')} ${data.customer_idcard}`, 4, total, null, null, 'left')
        total += spacer
        doc.text(
          `${i18n.t('receive_points')} ${data.order_point} ${i18n.t('point')}  ${i18n.t('reward_points')} ${formatPrice(
            data.customer_point || 0,
          )} ${i18n.t('point')}`,
          4,
          total,
          null,
          null,
          'left',
        )
        total += spacer
      } else {
        doc.text(`${data.company_name}`, 4, total, null, null, 'left')
        total += spacer
        doc.text(
          `${i18n.t('contacts')} ${data.customer_name} ${i18n.t('customer_code')} ${data.customer_id}`,
          4,
          total,
          null,
          null,
          'left',
        )
        total += spacer
        doc.text(`${i18n.t('tax_id')} ${data.company_tax}`, 4, total, null, null, 'left')
        total += spacer
        doc.text(
          `${i18n.t('receive_points')} ${data.order_pointsave} ${i18n.t('point')}  ${i18n.t(
            'reward_points',
          )} ${formatPrice(data.customer_point || 0)} ${i18n.t('point')}`,
          4,
          total,
          null,
          null,
          'left',
        )
        total += spacer
      }

      doc.setLineWidth(0.2)
      doc.setDrawColor('#37474F')
      doc.line(4, total - 2, 76, total - 2)
      doc.setLineWidth(0.2)

      const dataList = generateData()
      doc.text(`${i18n.t('list')}`, 4, total, null, null, 'left')
      doc.text(`${i18n.t('price')}`, 76, total, null, null, 'right')
      total += spacer
      dataList.forEach(item => {
        const text = doc
          .setFont('TH-Niramit', 'bold')
          .setFontSize(fontTitle.value)
          .splitTextToSize(`${item[0]}*${item[1]}`, 60)
        doc.text(`${item[5]}`, 76, total, null, null, 'right')
        text.forEach(element => {
          doc.text(element, 4, total, null, null, 'left')
          total += spacer
        })
      })
      doc.setDrawColor('#37474F')
      doc.line(4, total + 0.5, 76, total + 0.5)
      total += spacer
      const arr = [
        { name: `${(data.order_tax_id) == '2' ? i18n.t('valueBeforeVat') : i18n.t('valueBeforeVat1')}`, value: data.order_price },
        { name: i18n.t('discount'), value: data.order_save },
        { name: i18n.t('discount_points'), value: data.order_pointsave },
        {
          name:
            route.value.query.lang == '2'
              ? vatList.value[+data.order_tax_id - 1].en
              : vatList.value[+data.order_tax_id - 1].th,
          value: data.order_tax,
        },
        { name: `${(data.order_tax_id) == '2' ? i18n.t('valueBeforeVat1') : i18n.t('valueBeforeVat')}`, value: data.order_total },
        { name: i18n.t('netTotalPrice'), value: data.net_amount },
        { name: i18n.t('paymentMethod'), value: data.shop_bank_name || '' },
      ]

      for (let i = 0; i < arr.length; i++) {
        doc.text(arr[i].name, 4, total, null, null, 'left')
        doc.text(arr[i].value, 76, total, null, null, 'right')
        total += spacer
      }

      doc.setLineWidth(0.2)
      doc.setDrawColor('#37474F')
      doc.line(4, total + 2, 76, total + 2)
      doc.line(4, total + 3, 76, total + 3)
      doc.setFontSize(12)
      doc.text('***Thank you***', 40, total + 8, null, null, 'center')

      frame.value = doc.output('datauristring')
    }

    return { savePDF, frame }
  },
}
</script>
